import BaseModel from '../core/BaseModel';
import OrderStatusModel from "./OrderStatusModel";
import ChannelModel from "./ChannelModel";
export default class ReportOrderProgressModel extends BaseModel {
    constructor() {
        super({ 
                name: "report_order_progress", tableName: 'order_item', label: "Báo Cáo Tiến Trình Đơn Hàng", display_key: 'name'
            },
            [{
                stt: {
                    name: "stt",
                    label: "STT",
                    width: 60,
                    type: "Computed",
                    computed: (data, index) => {
                        data.stt = index + 1;
                    },
                    // display: { list: true, add: false, edit: false }
                },
            id: { 
                name: 'id', 
                type: "Uuid", 
                label: 'ID', 
                primaryKey: true, 
                display: false
            },
            channel_code: {
              name: "channel_code",
              
              label: "Kênh",
              type: "String",
              filter: { 
                type: 'input', 
                match: 'contains' 
              },
              computed: (data) => {
                data.channel_code = '';
              }
            },
            status_done: {
                name: 'status_done',
                type: "Number",
                class: 'right',
                label: 'Đã giao thành công',
                filter: { type: 'number', match: 'contains'},
                computed: (data) => {
                    data.status_done = '';
                }
            },
            waiting_delivery : { 
                name: 'waiting_delivery', 
                type: "Number", 
                label: 'Đang giao hàng',
                class: 'right',
                sortable: false,
                filter: { 
                    type: 'number', 
                    match: 'contains' 
                },
                computed: (data) => {
                    data.waiting_delivery = '';
                }
            },
            preparing_goods : { 
                name: 'preparing_goods', 
                type: "Number", 
                label: 'Đang soạn hàng',
                class: 'right',
                sortable: false,
                filter: { 
                    type: 'number', 
                    match: 'contains' 
                },
                computed: (data) => {
                    data.preparing_goods = '';
                }
            },
            waiting_payment : { 
                name: 'waiting_payment', 
                type: "Number", 
                label: 'Chờ thanh toán',
                class: 'right',
                sortable: false,
                filter: { 
                    type: 'number', 
                    match: 'contains' 
                },
                computed: (data) => {
                    data.waiting_payment = '';
                }
            },
            waiting_export : { 
                name: 'waiting_export', 
                type: "Number", 
                label: 'Chờ xuất',
                class: 'right',
                sortable: false,
                filter: { 
                    type: 'number', 
                    match: 'contains' 
                },
                computed: (data) => {
                    data.waiting_export = '';
                }
            },
            total : { 
                name: 'total', 
                type: "Number", 
                label: 'Tổng',
                class: 'right',
                sortable: false,
                filter: { 
                    type: 'number', 
                    match: 'contains' 
                },
                computed: (data) => {
                    data.total = '';
                }
            },
        },
        {
            stt: {
                name: "stt",
                label: "STT",
                width: 60,
                type: "Computed",
                computed: (data, index) => {
                    data.stt = index + 1;
                },
                // display: { list: true, add: false, edit: false }
            },
            id: { 
                name: 'id', 
                type: "Uuid", 
                label: 'ID', 
                primaryKey: true, 
                display: false
            },
            order: {
                refs: "order {code doc_code doc_created_at date_order type description customer_code order_status{name}}",
                display: false
            },
            warehouse_import: {
                refs: "warehouse{code name}",
                display: false
            },
            warehouse_export: {
                refs: "warehouseByWarehouseId{code name}",
                display: false
            },
            unit: {
                refs: "unit{name}",
                display: false
            },
            material: {
                refs: "material{name code}",
                display: false
            },
            product: { 
                refs: 'product {id name code}',
                display: false
            },
            date_order: {
                name: 'date_order',
                type: "Date",
                width: 126,
                label: 'Ngày đơn hàng',
                filter: { type: 'DateRange', match: 'contains'},
                computed: (data) => {
                    data.date_order = '';
                }
            },
            doc_created_at : { 
                name: 'doc_created_at', 
                type: "Date", 
                label: 'Ngày chứng từ',
                width: 126,
                sortable: false,
                filter: { type: 'DateRange', match: 'contains'},
                computed: (data) => {
                    data.doc_created_at = '';
                }
            },
            order_code : { 
                name: 'order_code', 
                type: "String", 
                label: 'Mã đơn hàng',
                width: 126,
                sortable: false,
                filter: { 
                    type: 'input', 
                    match: 'contains' 
                },
                computed: (data) => {
                    data.order_code = '-';
                }
            },
            doc_code : { 
                name: 'doc_code', 
                type: "String", 
                label: 'Số chứng từ',
                width: 126,
                sortable: false,
                filter: { 
                    type: 'input', 
                    match: 'contains' 
                },
                computed: (data) => {
                    data.doc_code = '-';
                }
            },
            description : { 
                name: 'description', 
                type: "String", 
                label: 'Diễn giải',
                width: 252,
                sortable: false,
                filter: { 
                    type: 'input', 
                    match: 'contains' 
                },
                computed: (data) => {
                    data.description = '-';
                }
            },
            customer_code : { 
                name: 'customer_code', 
                type: "String",
                class:'medium-col',
                label: 'Mã khách hàng',
                width: 126,
                sortable: false,
                filter: { 
                    type: 'input', 
                    match: 'contains' 
                },
                computed: (data) => {
                    data.customer_code = '-';
                }
            },
            customer_name : { 
                name: 'customer_name', 
                type: "String",
                class:'mi-medium-col',
                label: 'Tên khách hàng',
                width: 200,
                sortable: false,
                filter: { 
                    type: 'input', 
                    match: 'contains' 
                },
                computed: (data) => {
                    data.customer_name = '-';
                }
            },
            group_channel_code : { 
                name: 'group_channel_code', 
                type: "String", 
                label: 'Nhóm kênh khách hàng',
                class:'medium-col',
                width: 252,
                sortable: false,
                filter: { 
                    type: 'input', 
                    match: 'contains' 
                },
                computed: (data) => {
                    data.group_channel_code = '-';
                }
            },
            suggest_delivery_type : { 
                name: 'suggest_delivery_type', 
                type: "Selection",
                custom: 'SelectionText',
                label: 'Phương thức giao hàng',
                width: 252,
                sortable: false,
                options:
                [{
                    value: 1,
                    label: 'Giao hàng đến địa chỉ khách',
                },{   
                    value: 2,
                    label: 'Giao hàng đến chành',
                },{
                    value: 3,
                    label: 'Gửi bưu điện',
                },{
                    value: 4,
                    label: 'Tự lấy hàng'
                }],
                filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
                computed: (data) => {
                    data.suggest_delivery_type = '-';
                }
            },
            total_package : { 
                name: 'total_package', 
                type: "Number", 
                label: 'Tổng kiện hàng',
                width: 126,
                class: "right",
                sortable: false,
                filter: { 
                    type: 'number',
                    match: 'contains' 
                },
                computed: (data) => {
                    data.total_package = 0;
                }
            },
            delivery_eligibility_date: {
                name: 'delivery_eligibility_date',
                type: "Date",
                label: 'Ngày đủ điều kiện giao hàng',
                class: "medium-col style-text-date",
                filter: { type: 'DateRange', match: 'contains'},
            },
            date_done: {
                name: 'date_done',
                type: "Date",
                label: 'Ngày giao hàng thành công',
                class: "medium-col style-text-date",
                filter: { type: 'DateRange', match: 'contains'},
            },
            /*delivery_eligibility_date : {
                name: 'delivery_eligibility_date', 
                type: "Date", 
                label: 'Ngày đủ điều kiện giao hàng',
                width: 126,
                sortable: false,
                filter: { type: 'Date', match: 'contains'},
                computed: (data) => {
                    data.delivery_eligibility_date = '';
                }
            },
            date_done : { 
                name: 'date_done', 
                type: "Date", 
                label: 'Ngày giao hàng thành công',
                width: 126,
                sortable: false,
                filter: { type: 'Date', match: 'contains'},
                computed: (data) => {
                    data.date_done = '';
                }
            },*/
            order_status: {
                width: 126,
                name: 'order_status', 
                type: "Selection",
                custom: 'SelectionText',
                label: 'Tình trạng',
                models: new OrderStatusModel(),
                filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
                computed: (data) => {
                    data.order_status = 0;
                }
            },
            /*type_order_status: {
                width: 126,
                name: 'type_order_status', 
                type: "Selection",
                custom: 'SelectionText',
                label: 'Phân loại đơn hàng',
                models: new OrderStatusModel(),
                filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
                computed: (data) => {
                    data.type_order_status = 0;
                }
            },*/
            type_order_status: {
                name: "type_order_status",
                label: 'Phân loại đơn hàng',
                type: "Selection",
                custom: 'SelectionText',
                options: [
                    {value: ['ST013','ST014','ST015'], label: 'Đã giao thành công', style: 'color:  #000000;'},
                    {value: ['ST008','ST009','ST010','ST011','ST012'], label: 'Đang giao hàng', style: 'color:  #000000;'},
                    {value: ['ST003','ST004'], label: 'Đang soạn hàng', style: 'color:  #000000;'},
                    {value: 'not_paid', label: 'Chờ thanh toán', style: 'color:  #000000;'},
                    {value: ['ST005','ST006','ST007'], label: 'Chờ xuất', style: 'color:  #000000;'},
                ],
                filter: {
                    type: 'select',
                    optionLabel: 'label',
                    optionKey: 'value',
                    match: 'equals'
                },
                class:"center width_90 common",
            },
            channel_code: {
                width: 126,
                name: 'channel_code', 
                type: "Selection",
                custom: 'SelectionText',
                label: 'Kênh phân phối',
                models: new ChannelModel(),
                filter: { type: 'select', optionLabel: 'label', optionKey: 'value', match: 'equals' },
                computed: (data) => {
                    data.channel_code = 0;
                }
            },
            
        }
        ])
    }

}